var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"similar-section"},[_c('span',{staticClass:"title margin-container"},[_vm._v(" "+_vm._s(_vm.$t('sections.property-detail.similar'))+" ")]),(_vm.getBreakpoints.lgAndUp)?_c('div',{staticClass:"properties margin-container"},_vm._l((_vm.similarProperties),function(prop,index){return _c('PropertyCard',{key:("similar-property-" + index),attrs:{"property":prop},on:{"click":function($event){return _vm.$router.push({
          name: 'property-detail',
          params: {
            id: _vm.property._id,
          },
        })}}})}),1):_c('flickity',{ref:"similarProperties",staticClass:"properties-mobile",attrs:{"options":_vm.similarPropertiesflickityOptions}},_vm._l((_vm.similarProperties),function(prop,index){return _c('PropertyCard',{key:("similar-property-mobile-" + index),staticClass:"mb-8 mt-6",class:index !== 0 &&
          index + 1 !== 3 &&
          (_vm.getBreakpoints.mdAndDown ? 'mx-7' : 'mx-10'),attrs:{"property":prop},on:{"click":function($event){return _vm.$router.push({
          name: 'property-detail',
          params: {
            id: _vm.property._id,
          },
        })}}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }