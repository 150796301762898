<template>
  <div class="description-section margin-container">
    <div class="description-section__top">
      <div class="upper-section">
        <span class="location">{{ location }}</span>
        <!-- TODO <div :class="getBreakpoints.smAndUp && 'description-section__right'">
          <div
            class="favorite-button"
            @click="$emit('favorite')"
          >
            <span v-if="getBreakpoints.smAndUp">
              {{
                $t('sections.property-detail.description-section.favoriteButton')
              }}
            </span>
            <span class="mdi mdi-heart-outline" />
          </div>
        </div> -->
      </div>
      <span class="title">{{ title }}</span>
    </div>
    <div class="description-section__bottom">
      <div class="description-section__info">
        <span class="price">
          {{
            `${numberToDotNotationString(price)}€${
              isRent
                ? $t('sections.property-detail.description-section.eachMonth')
                : ''
            }`
          }}
        </span>
        <div class="main-characteristics">
          <div class="main-group">
            <img
              :src="require(`@/assets/svgs/energeticClasses/${characteristics.energeticClass}.svg`)"
              class="energetic-class"
              v-if="characteristics.energeticClass"
            >
            <span>
              {{ characteristics.typology }}
            </span>
            <span>
              {{ floorText }}
            </span>
            <span>
              {{ `${characteristics.area}m²` }}
            </span>
          </div>
          <div class="icon-groups">
            <div class="icon-group">
              <span class="mdi mdi-bed-outline" />
              <span>
                {{ characteristics.beds }}
              </span>
            </div>
            <div class="icon-group">
              <span class="mdi mdi-shower" />
              <span>
                {{ characteristics.bathrooms }}
              </span>
            </div>
            <div class="icon-group">
              <span class="mdi mdi-car-outline" />
              <span>
                {{ characteristics.garageSpots }}
              </span>
            </div>
            <!-- <div class="icon-group">
              <span class="mdi mdi-elevator-passenger-outline" />
              <span>
                {{ characteristics.elevators }}
              </span>
            </div> -->
          </div>
        </div>
        <span
          class="description"
          v-html="description"
        />
        <BaseButton
          large
          :label="
            $t('sections.property-detail.description-section.proposalButton')
          "
          @click="$emit('proposal')"
          class="proposal-button"
        />
      </div>
      <div class="description-section__owner description-section__right">
        <img
          :src="owner.image || require(`@/assets/svgs/user_placeholder.svg`)"
          class="description-section__owner__image"
        >
        <div class="description-section__owner__text">
          <span class="description-section__owner__name">
            {{ owner.name }}
          </span>
          <!-- TODO <span class="description-section__owner__phone">
            {{ owner.phone }}
          </span> -->
        </div>
        <div class="description-section__owner__buttons">
          <BaseButton
            :label="
              $t('sections.property-detail.description-section.contactButton')
            "
            @click="$emit('contact')"
          />
          <!-- TODO <BaseButton
            type="outlined"
            :label="
              $t('sections.property-detail.description-section.visitButton')
            "
            @click="$emit('visit')"
          /> -->
        </div>
        <!-- TODO
        <BaseButton
          type="outlined"
          @click="$emit('virtualVisit')"
          class="virtual-visit-button"
        >
          <template slot>
            <span class="mdi mdi-rotate-360" />
            <span>
              {{
                $t(
                  'sections.property-detail.description-section.virtualVisitButton',
                )
              }}
            </span>
          </template>
        </BaseButton>
        -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BaseButton } from 'ff-components-lib';
import { numberToDotNotationString, validateNumber } from '@/utils';

export default {
  name: 'DescriptionSection',
  components: {
    BaseButton,
  },
  props: {
    location: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    characteristics: {
      type: Object,
      default: () => {},
    },
    description: {
      type: String,
      default: '',
    },
    owner: {
      type: Object,
      default: () => {},
    },
    isRent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
    floorText() {
      if (
        this.characteristics.floor != null
        && validateNumber(this.characteristics.floor)
      ) {
        return `${this.characteristics.floor}º ${this.$t(
          'sections.property-detail.description-section.floor',
        )}`;
      }
      return this.characteristics.floor;
    },
  },
  methods: {
    numberToDotNotationString,
  },
};
</script>
<style lang="scss" scoped>
.description-section {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 48px 48px;
  background: white;
  box-shadow: 0px 4px 80px 0px rgba($primary, 0.3);
  padding: 48px 114px 70px 114px;

  &__top {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;

    .upper-section {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .location {
        color: $grey;
        font-size: 18px;
        line-height: 26px;
      }

      .favorite-button {
        display: flex;
        gap: 8px;
        font-weight: 500;
        color: $grey;
        cursor: pointer;
      }
    }

    .title {
      font-size: 30px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.72px;
      margin-top: 4px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;

    .description-section__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;

      .price {
        font-size: 48px;
        font-weight: 500;
      }

      .main-characteristics {
        display: flex;
        align-items: center;
        gap: 30px;
        color: $grey;
        font-weight: 600;

        .main-group {
          display: flex;
          align-items: center;
          gap: 12px;

          .energetic-class {
            width: 30px;
            height: 30px;
            line-height: 1;
          }
        }

        .icon-groups {
          display: flex;
          align-items: center;
          gap: 30px;

          .icon-group {
            display: flex;
            align-items: center;
            gap: 4px;

            & > .mdi {
              width: 26px;
              height: 26px;
              font-size: 26px;
              line-height: 1;
            }
          }
        }
      }

      .description {
        line-height: 24px;
        white-space: break-spaces;
      }
    }

    .description-section__owner {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;

      &__image {
        width: 138px;
        height: 138px;
        aspect-ratio: 1;
        border-radius: 50%;
      }

      &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__name {
        font-size: 24px;
        font-weight: 600;
        line-height: 26px;
      }

      &__phone {
        color: $grey;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .base-button.outlined {
        background: unset;
      }
    }

    .virtual-visit-button {
      display: flex;
      align-items: center;
      gap: 6px;
      background: unset;
      margin-top: auto;

      .mdi-rotate-360 {
        font-size: 24px;
        line-height: 1;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    min-width: 200px;
  }
}

@media only screen and (max-width: 970px) {
  .description-section {
    padding: 24px 24px 56px 24px;

    &__top {
      margin-bottom: 24px;

      .upper-section {
        gap: 8px;

        .location {
          font-size: 16px;
          line-height: 24px;
        }

        .favorite-button {
          gap: 0;
        }
      }

      .title {
        font-size: 24px;
        letter-spacing: -0.48px;
      }
    }

    &__bottom {
      .description-section__info {
        gap: 24px;

        .price {
          font-size: 40px;
        }

        .main-characteristics {
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;

          .main-group {
            gap: 10px;

            .energetic-class {
              width: 22px;
              height: 22px;
            }
          }

          .icon-groups {
            gap: 12px;

            .icon-group {
              gap: 4px;

              & > .mdi {
                width: 20px;
                height: 20px;
                font-size: 20px;
              }
            }
          }
        }

        .proposal-button {
          align-self: center;
        }
      }

      .description-section__owner {
        gap: 16px;

        &__image {
          width: 100px;
          height: 100px;
        }

        &__name {
          font-size: 20px;
          line-height: 26px;
        }

        &__phone {
          font-size: 12px;
        }

        &__buttons {
          gap: 12px;
        }
      }
    }
  }
}

@media only screen and (max-width: 670px) {
  .description-section {
    &__bottom {
      flex-direction: column;
      gap: 56px;

      .description-section__owner {
        align-self: center;
      }
    }
  }
}
</style>
