var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GradientModal',{attrs:{"open":_vm.modalOpen,"button":_vm.$t('sections.property-detail.visitModal.button'),"button-disabled":!_vm.validFields},on:{"buttonClick":_vm.handleSubmit,"close":_vm.handleClose}},[_c('template',{slot:"default"},[_c('span',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.$t('sections.property-detail.visitModal.title'))+" ")]),_c('div',{staticClass:"collapse-group"},[_c('BlueCollapse',{attrs:{"start-open":"","title":_vm.$t('sections.property-detail.visitModal.clientType')}},[_c('template',{slot:"default"},[_c('div',{staticClass:"slotted-choice-card-group"},[_c('SlottedChoiceCard',{attrs:{"text":_vm.$t(
                  'sections.property-detail.visitModal.clientTypeSelection[0]'
                ),"active":_vm.inputs.clientType === 0},on:{"click":function($event){return _vm.handleInput('clientType', 0)}}}),_c('SlottedChoiceCard',{attrs:{"text":_vm.$t(
                  'sections.property-detail.visitModal.clientTypeSelection[1]'
                ),"divider":"","active":_vm.inputs.clientType === 1},on:{"click":function($event){return _vm.handleInput('clientType', 1)}}},[_c('template',{slot:"default"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"input-type":"tertiary","title":_vm.$t(
                          'sections.property-detail.visitModal.agencyName.value'
                        ),"placeholder":_vm.$t(
                          'sections.property-detail.visitModal.agencyName.placeholder'
                        ),"value":_vm.inputs.agency,"valid":_vm.validAgency,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('agency', e); }}})],1),_c('div',{staticClass:"col-12 col-sm-6 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"input-type":"tertiary","title":_vm.$t(
                          'sections.property-detail.visitModal.amiLicense.value'
                        ),"placeholder":_vm.$t(
                          'sections.property-detail.visitModal.amiLicense.placeholder'
                        ),"value":_vm.inputs.amiLicense,"valid":_vm.validAMILicense,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('amiLicense', e); }}})],1)])])],2)],1)])],2),_c('BlueCollapse',{attrs:{"start-open":"","title":_vm.$t('sections.property-detail.visitModal.dateAndHour')}},[_c('template',{slot:"default"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-6 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t('sections.property-detail.visitModal.date.value'),"placeholder":_vm.$t('sections.property-detail.visitModal.date.placeholder'),"value":_vm.inputs.date,"valid":_vm.validDate,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('date', e); }}})],1),_c('div',{staticClass:"col-12 col-sm-6 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t('sections.property-detail.visitModal.hour.value'),"placeholder":_vm.$t('sections.property-detail.visitModal.hour.placeholder'),"value":_vm.inputs.hour,"valid":_vm.validHour,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('hour', e); }}})],1)])])],2),_c('BlueCollapse',{attrs:{"start-open":"","title":_vm.$t('sections.property-detail.visitModal.contactInfo')}},[_c('template',{slot:"default"},[_c('VerticalTitleInput',{staticClass:"inputs mb-6",attrs:{"title":_vm.$t('sections.property-detail.visitModal.name.value'),"placeholder":_vm.$t('sections.property-detail.visitModal.name.placeholder'),"value":_vm.inputs.name,"valid":_vm.validName,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('name', e); }}}),_c('div',{staticClass:"row inputs m-0"},[_c('div',{staticClass:"col-sm-6 col-12 pl-0 pr-3 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
                    'sections.property-detail.visitModal.email.value'
                  ),"placeholder":_vm.$t(
                    'sections.property-detail.visitModal.email.placeholder'
                  ),"value":_vm.inputs.email,"valid":this.validEmail,"error-label":_vm.$t('components.input.invalidEmail')},on:{"input":function (e) { return _vm.handleInput('email', e); }}})],1),_c('div',{staticClass:"col-sm-6 col-12 pl-3 pr-0 mb-sm-0 mb-6"},[_c('PhoneInput',{attrs:{"name":"phone-number","title":_vm.$t(
                    'sections.property-detail.visitModal.phone.value'
                  ),"placeholder":_vm.$t(
                    'sections.property-detail.visitModal.phone.placeholder'
                  ),"code-placeholder":_vm.$t(
                    'sections.property-detail.visitModal.countryCode.placeholder'
                  ),"valid":this.validPhone,"valid-code":this.validPhoneCountryCode,"error-label":_vm.$t('components.input.invalidPhone'),"value":_vm.inputs.phone,"code-value":_vm.inputs.countryCode,"items":_vm.$t('arrays.phoneCountryCodes')},on:{"input":function (e) { return _vm.handleInput('phone', e); },"codeInput":function (e) { return _vm.handleInput('countryCode', e); }}})],1)])],1)],2)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }