<template>
  <div class="characteristics-section margin-container">
    <span class="title">
      {{ $t('sections.property-detail.characteristics.title') }}
    </span>
    <div class="characteristics">
      <div
        v-if="characteristics.length <= 3"
        class="row"
      >
        <div class="col-12 characteristics__column">
          <span
            v-for="(characteristic, index) in characteristics"
            :key="`characteristic-1-${index}`"
          >
            {{ characteristic }}
          </span>
        </div>
      </div>
      <div
        v-else-if="characteristics.length > 3 && characteristics.length <= 6"
        class="row"
      >
        <div
          class="col-12 col-sm-6"
          v-for="(characteristic, index) in characteristics"
          :key="`characteristic-2-${index}`"
        >
          <span>
            {{ characteristic }}
          </span>
        </div>
      </div>
      <div
        v-else
        class="row"
      >
        <div
          class="col-12 col-md-6 col-lg-4"
          v-for="(characteristic, index) in characteristics"
          :key="`characteristic-3-${index}`"
        >
          <span>
            {{ characteristic }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CharacteristicsSection',
  props: {
    characteristics: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.characteristics-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 7%;
  padding-right: 7%;

  .title {
    font-size: 36px;
    color: $grey;
    line-height: 65px;
  }

  .characteristics {
    font-size: 20px;
    line-height: 36px;

    &__column {
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 770px) {
  .characteristics-section {
    gap: 24px;
    padding-left: 3%;
    padding-right: 3%;

    .title {
      font-size: 24px;
      line-height: 44px;
    }

    .characteristics {
      font-size: 14px;
      line-height: 25px;
    }
  }
}
</style>
