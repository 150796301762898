<template>
  <GradientModal
    :open="modalOpen"
    :button="$t('sections.property-detail.contactModal.button')"
    :button-disabled="!validFields"
    @buttonClick="handleSubmit"
    @close="handleClose"
  >
    <template slot>
      <span class="modal-title">
        {{ $t('sections.property-detail.contactModal.title') }}
      </span>
      <VerticalTitleInput
        :title="$t('sections.property-detail.contactModal.inputs.subject.value')"
        :placeholder="$t('sections.property-detail.contactModal.inputs.subject.placeholder')"
        :value="inputs.subject"
        :valid="validSubject"
        :error-label="$t('components.input.missingField')"
        @input="(e) => handleInput('subject', e)"
        class="inputs"
      />
      <VerticalTitleInput
        type="textarea"
        :title="$t('sections.property-detail.contactModal.inputs.description.value')"
        :placeholder="$t('sections.property-detail.contactModal.inputs.description.placeholder')"
        :value="inputs.description"
        :valid="validDescription"
        :error-label="$t('components.input.missingField')"
        @input="(e) => handleInput('description', e)"
        class="inputs"
      />
      <VerticalTitleInput
        :title="$t('sections.property-detail.contactModal.inputs.name.value')"
        :placeholder="$t('sections.property-detail.contactModal.inputs.name.placeholder')"
        :value="inputs.name"
        :valid="validName"
        :error-label="$t('components.input.missingField')"
        @input="(e) => handleInput('name', e)"
        class="inputs"
      />
      <div class="row inputs m-0">
        <div class="col-sm-6 col-12 pl-0 pr-3 mb-sm-0 mb-6">
          <VerticalTitleInput
            :title="
              $t(
                'sections.property-detail.contactModal.inputs.email.value'
              )
            "
            :placeholder="
              $t(
                'sections.property-detail.contactModal.inputs.email.placeholder'
              )
            "
            :value="inputs.email"
            :valid="this.validEmail"
            :error-label="$t('components.input.invalidEmail')"
            @input="(e) => handleInput('email', e)"
          />
        </div>
        <div class="col-sm-6 col-12 pl-3 pr-0 mb-sm-0 mb-6">
          <PhoneInput
            name="phone-number"
            :title="
              $t(
                'sections.property-detail.contactModal.inputs.phone.value'
              )
            "
            :placeholder="
              $t(
                'sections.property-detail.contactModal.inputs.phone.placeholder'
              )
            "
            :code-placeholder="
              $t(
                'sections.property-detail.contactModal.inputs.countryCode.placeholder'
              )
            "
            :valid="this.validPhone"
            :valid-code="this.validPhoneCountryCode"
            :error-label="$t('components.input.invalidPhone')"
            :value="inputs.phone"
            :code-value="inputs.countryCode"
            :items="$t('arrays.phoneCountryCodes')"
            @input="(e) => handleInput('phone', e)"
            @codeInput="(e) => handleInput('countryCode', e)"
          />
        </div>
      </div>
      <BaseCheckbox
        :label="$t('sections.property-detail.contactModal.consent')"
        :value="consent"
        class="inputs"
        @input="(e) => consent = e"
      />
    </template>
  </GradientModal>
</template>
<script>
import {
  GradientModal,
  VerticalTitleInput,
  BaseCheckbox,
  PhoneInput,
} from 'ff-components-lib';
import { validateEmail, validatePhone } from '@/utils';

export default {
  name: 'ContactModal',
  components: {
    GradientModal,
    VerticalTitleInput,
    BaseCheckbox,
    PhoneInput,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        subject: undefined,
        description: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      },
      consent: false,
    };
  },
  computed: {
    validSubject() {
      return (
        this.inputs.subject === undefined
        || (typeof this.inputs.subject === 'string' && this.inputs.subject.length > 0)
      );
    },
    validDescription() {
      return (
        this.inputs.description === undefined
        || (typeof this.inputs.description === 'string' && this.inputs.description.length > 0)
      );
    },
    validName() {
      return (
        this.inputs.name === undefined
        || (typeof this.inputs.name === 'string' && this.inputs.name.length > 0)
      );
    },
    validEmail() {
      if (this.inputs.email === undefined) {
        return true;
      }
      if (this.inputs.email && this.inputs.email.length > 0) {
        return validateEmail(this.inputs.email);
      }
      return false;
    },
    validPhoneCountryCode() {
      return (
        this.inputs.countryCode === undefined
        || (typeof this.inputs.countryCode === 'string'
          && this.inputs.countryCode.length > 0)
      );
    },
    validPhone() {
      if (this.inputs.phone === undefined) {
        return true;
      }
      if (this.inputs.phone && this.inputs.phone.length > 0) {
        return validatePhone(this.inputs.phone);
      }
      return false;
    },
    validFields() {
      return (
        this.inputs.subject
        && this.validSubject
        && this.inputs.description
        && this.validDescription
        && this.inputs.name
        && this.validName
        && this.inputs.email
        && this.validEmail
        && this.inputs.countryCode
        && this.validPhoneCountryCode
        && this.inputs.phone
        && this.validPhone
        && this.consent
      );
    },
  },
  methods: {
    handleInput(field, value) {
      this.inputs[field] = value;
      this.$emit('input', { field, value });
    },
    handleSubmit() {
      this.$emit('submit');
      this.inputs = {
        subject: undefined,
        description: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      };
      this.consent = false;
    },
    handleClose() {
      this.$emit('close');
      this.inputs = {
        subject: undefined,
        description: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      };
      this.consent = false;

      // TODO
      /*
      if (this.wasEdited) {
        this.$confirm(this.$t('pages.details.confirm.message'), '', {
          confirmButtonText: this.$t('pages.details.yesButton'),
          cancelButtonText: this.$t('pages.details.cancelButton'),
          cancelButtonClass: 'outline-button',
        })
          .then(() => {
            this.$emit('close');
            this.newChargingPeriod = {
              start_date_time: undefined,
              dimensions: {
                type: undefined,
                volume: undefined,
              },
              tariff_id: undefined,
            };
            this.key += 1;
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('pages.details.confirm.cancelMessage'),
            });
          });
      } else {
        this.$emit('close');
        this.newChargingPeriod = {
          start_date_time: undefined,
          dimensions: {
            type: undefined,
            volume: undefined,
          },
          tariff_id: undefined,
        };
        this.key += 1;
      }
      */
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  width: 85%;
}

.inputs {
  width: 85%;
}

@media only screen and (max-width: 900px) {
  .modal-title,
  .inputs {
    width: 90%;
  }
}

@media only screen and (max-width: 770px) {
  .modal-title {
    font-size: 20px;
  }

  .modal-title,
  .inputs {
    width: 100%;
  }
}
</style>
