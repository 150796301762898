<template>
  <GradientModal
    :open="modalOpen"
    :button="$t('sections.property-detail.visitModal.button')"
    :button-disabled="!validFields"
    @buttonClick="handleSubmit"
    @close="handleClose"
  >
    <template slot>
      <span class="modal-title">
        {{ $t('sections.property-detail.visitModal.title') }}
      </span>
      <div class="collapse-group">
        <BlueCollapse
          start-open
          :title="$t('sections.property-detail.visitModal.clientType')"
        >
          <template slot>
            <div class="slotted-choice-card-group">
              <SlottedChoiceCard
                :text="
                  $t(
                    'sections.property-detail.visitModal.clientTypeSelection[0]',
                  )
                "
                :active="inputs.clientType === 0"
                @click="handleInput('clientType', 0)"
              />
              <SlottedChoiceCard
                :text="
                  $t(
                    'sections.property-detail.visitModal.clientTypeSelection[1]',
                  )
                "
                divider
                :active="inputs.clientType === 1"
                @click="handleInput('clientType', 1)"
              >
                <template slot>
                  <div class="row">
                    <div class="col-12 col-sm-6 mb-sm-0 mb-6">
                      <VerticalTitleInput
                        input-type="tertiary"
                        :title="
                          $t(
                            'sections.property-detail.visitModal.agencyName.value',
                          )
                        "
                        :placeholder="
                          $t(
                            'sections.property-detail.visitModal.agencyName.placeholder',
                          )
                        "
                        :value="inputs.agency"
                        :valid="validAgency"
                        :error-label="$t('components.input.missingField')"
                        @input="(e) => handleInput('agency', e)"
                      />
                    </div>
                    <div class="col-12 col-sm-6 mb-sm-0 mb-6">
                      <VerticalTitleInput
                        input-type="tertiary"
                        :title="
                          $t(
                            'sections.property-detail.visitModal.amiLicense.value',
                          )
                        "
                        :placeholder="
                          $t(
                            'sections.property-detail.visitModal.amiLicense.placeholder',
                          )
                        "
                        :value="inputs.amiLicense"
                        :valid="validAMILicense"
                        :error-label="$t('components.input.missingField')"
                        @input="(e) => handleInput('amiLicense', e)"
                      />
                    </div>
                  </div>
                </template>
              </SlottedChoiceCard>
            </div>
          </template>
        </BlueCollapse>
        <BlueCollapse
          start-open
          :title="$t('sections.property-detail.visitModal.dateAndHour')"
        >
          <template slot>
            <div class="row">
              <div class="col-12 col-sm-6 mb-sm-0 mb-6">
                <VerticalTitleInput
                  :title="$t('sections.property-detail.visitModal.date.value')"
                  :placeholder="
                    $t('sections.property-detail.visitModal.date.placeholder')
                  "
                  :value="inputs.date"
                  :valid="validDate"
                  :error-label="$t('components.input.missingField')"
                  @input="(e) => handleInput('date', e)"
                />
              </div>
              <div class="col-12 col-sm-6 mb-sm-0 mb-6">
                <VerticalTitleInput
                  :title="$t('sections.property-detail.visitModal.hour.value')"
                  :placeholder="
                    $t('sections.property-detail.visitModal.hour.placeholder')
                  "
                  :value="inputs.hour"
                  :valid="validHour"
                  :error-label="$t('components.input.missingField')"
                  @input="(e) => handleInput('hour', e)"
                />
              </div>
            </div>
          </template>
        </BlueCollapse>
        <BlueCollapse
          start-open
          :title="$t('sections.property-detail.visitModal.contactInfo')"
        >
          <template slot>
            <VerticalTitleInput
              :title="$t('sections.property-detail.visitModal.name.value')"
              :placeholder="$t('sections.property-detail.visitModal.name.placeholder')"
              :value="inputs.name"
              :valid="validName"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('name', e)"
              class="inputs mb-6"
            />
            <div class="row inputs m-0">
              <div class="col-sm-6 col-12 pl-0 pr-3 mb-sm-0 mb-6">
                <VerticalTitleInput
                  :title="
                    $t(
                      'sections.property-detail.visitModal.email.value'
                    )
                  "
                  :placeholder="
                    $t(
                      'sections.property-detail.visitModal.email.placeholder'
                    )
                  "
                  :value="inputs.email"
                  :valid="this.validEmail"
                  :error-label="$t('components.input.invalidEmail')"
                  @input="(e) => handleInput('email', e)"
                />
              </div>
              <div class="col-sm-6 col-12 pl-3 pr-0 mb-sm-0 mb-6">
                <PhoneInput
                  name="phone-number"
                  :title="
                    $t(
                      'sections.property-detail.visitModal.phone.value'
                    )
                  "
                  :placeholder="
                    $t(
                      'sections.property-detail.visitModal.phone.placeholder'
                    )
                  "
                  :code-placeholder="
                    $t(
                      'sections.property-detail.visitModal.countryCode.placeholder'
                    )
                  "
                  :valid="this.validPhone"
                  :valid-code="this.validPhoneCountryCode"
                  :error-label="$t('components.input.invalidPhone')"
                  :value="inputs.phone"
                  :code-value="inputs.countryCode"
                  :items="$t('arrays.phoneCountryCodes')"
                  @input="(e) => handleInput('phone', e)"
                  @codeInput="(e) => handleInput('countryCode', e)"
                />
              </div>
            </div>
          </template>
        </BlueCollapse>
      </div>
    </template>
  </GradientModal>
</template>
<script>
import {
  GradientModal,
  BlueCollapse,
  SlottedChoiceCard,
  VerticalTitleInput,
  PhoneInput,
} from 'ff-components-lib';
import { validateEmail, validatePhone } from '@/utils';

export default {
  name: 'VisitModal',
  components: {
    GradientModal,
    BlueCollapse,
    SlottedChoiceCard,
    VerticalTitleInput,
    PhoneInput,
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        clientType: undefined,
        agency: undefined,
        amiLicense: undefined,
        date: undefined,
        hour: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      },
    };
  },
  computed: {
    validAgency() {
      return (
        this.inputs.agency === undefined
        || (typeof this.inputs.agency === 'string' && this.inputs.agency.length > 0)
      );
    },
    validAMILicense() {
      return (
        this.inputs.amiLicense === undefined
        || (typeof this.inputs.amiLicense === 'string' && this.inputs.amiLicense.length > 0)
      );
    },
    validDate() {
      return (
        this.inputs.date === undefined
        || (typeof this.inputs.date === 'string' && this.inputs.date.length > 0)
      );
    },
    validHour() {
      return (
        this.inputs.hour === undefined
        || (typeof this.inputs.hour === 'string' && this.inputs.hour.length > 0)
      );
    },
    validName() {
      return (
        this.inputs.name === undefined
        || (typeof this.inputs.name === 'string' && this.inputs.name.length > 0)
      );
    },
    validEmail() {
      if (this.inputs.email === undefined) {
        return true;
      }
      if (this.inputs.email && this.inputs.email.length > 0) {
        return validateEmail(this.inputs.email);
      }
      return false;
    },
    validPhoneCountryCode() {
      return (
        this.inputs.countryCode === undefined
        || (typeof this.inputs.countryCode === 'string'
          && this.inputs.countryCode.length > 0)
      );
    },
    validPhone() {
      if (this.inputs.phone === undefined) {
        return true;
      }
      if (this.inputs.phone && this.inputs.phone.length > 0) {
        return validatePhone(this.inputs.phone);
      }
      return false;
    },
    validFields() {
      return (
        this.inputs.clientType != null
        && (this.inputs.clientType === 0
          || (this.inputs.clientType === 1
            && this.inputs.agency
            && this.validAgency
            && this.inputs.amiLicense
            && this.validAMILicense))
        && this.inputs.date
        && this.validDate
        && this.inputs.hour
        && this.validHour
        && this.inputs.name
        && this.validName
        && this.inputs.email
        && this.validEmail
        && this.inputs.countryCode
        && this.validPhoneCountryCode
        && this.inputs.phone
        && this.validPhone
      );
    },
  },
  methods: {
    handleInput(field, value) {
      this.inputs[field] = value;
      this.$emit('input', { field, value });
    },
    handleSubmit() {
      this.$emit('submit');
      this.inputs = {
        clientType: undefined,
        agency: undefined,
        amiLicense: undefined,
        date: undefined,
        hour: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      };
    },
    handleClose() {
      this.$emit('close');
      this.inputs = {
        clientType: undefined,
        agency: undefined,
        amiLicense: undefined,
        date: undefined,
        hour: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  width: 85%;
}

.collapse-group {
  width: 85%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.slotted-choice-card-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media only screen and (max-width: 900px) {
  .modal-title,
  .collapse-group {
    width: 90%;
  }
}

@media only screen and (max-width: 770px) {
  .modal-title {
    font-size: 20px;
  }

  .modal-title,
  .collapse-group {
    width: 100%;
  }
}
</style>
