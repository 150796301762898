<template>
  <layout>
    <template slot="body">
      <flickity
        class="photos-section"
        ref="flickity"
        :options="flickityOptions"
        v-if="property.photos && property.photos.length > 0"
      >
        <div
          class="carousel-photo"
          v-for="(photo, index) in property.photos"
          :key="`property-image-${index}`"
        >
          <img
            :src="
              `https://storage.googleapis.com/bk-firstfloor-properties/${property._id}/photos/${photo}`
            "
          >
        </div>
      </flickity>
      <div
        class="photos-section"
        v-else
      >
        <img
          class="carousel-photo"
          :src="require(`@/assets/imgs/property_placeholder.jpg`)"
        >
      </div>

      <div class="gradient-section">
        <DescriptionSection
          :location="`${property.district}, ${property.city}`"
          :title="property.title"
          :characteristics="{
            energeticClass: property.energeticClass,
            typology: property.typology,
            floor: property.floor,
            area: property.area,
            beds: property.typology ? Number(property.typology.slice(1)) : '',
            bathrooms: property.wcs,
            garageSpots: property.garageSpaces,
            // elevators: property.elevators,
          }"
          :is-rent="property.listingType === 'RENT'"
          :price="property.value"
          :description="property.description"
          :owner="{
            name: property.ownerName,
          }"
          @contact="contactModalOpen = true"
          @visit="visitModalOpen = true"
          @proposal="rentModalOpen = true"
        />

        <CharacteristicsSection
          :characteristics="property.characteristics"
          v-if="property.characteristics && property.characteristics.length > 0"
        />
      </div>

      <MapSection
        v-if="
          property.coordinates &&
            property.coordinates.latitude != null &&
            property.coordinates.longitude != null
        "
        :coordinates="[
          property.coordinates.latitude,
          property.coordinates.longitude,
        ]"
      />

      <!-- TODO <FinancingSection /> -->

      <SimilarPropertiesSection
        :similar-properties="similarProperties"
        v-if="similarProperties && similarProperties.length > 0"
      />

      <ContactModal
        :modal-open="contactModalOpen"
        @input="handleContactInput"
        @submit="handleContactSubmition"
        @close="closeContactModal"
      />
      <VisitModal
        :modal-open="visitModalOpen"
        @input="handleVisitInput"
        @submit="handleVisitSubmition"
        @close="closeVisitModal"
      />
      <RentModal
        :modal-open="rentModalOpen"
        @input="handleRentInput"
        @submit="handleRentSubmition"
        @close="closeRentModal"
      />
    </template>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex';
import Flickity from 'vue-flickity';
import Layout from '@/components/layout/Layout';
import DescriptionSection from '@/components/sections/external/property-detail/DescriptionSection';
import CharacteristicsSection from '@/components/sections/external/property-detail/CharacteristicsSection';
import MapSection from '@/components/sections/external/property-detail/MapSection';
// import FinancingSection from '@/components/sections/external/property-detail/FinancingSection';
import SimilarPropertiesSection from '@/components/sections/external/property-detail/SimilarPropertiesSection';
import ContactModal from '@/components/sections/external/property-detail/ContactModal';
import VisitModal from '@/components/sections/external/property-detail/VisitModal';
import RentModal from '@/components/sections/external/property-detail/RentModal';

export default {
  name: 'PropertyDetailScreen',
  components: {
    Layout,
    DescriptionSection,
    CharacteristicsSection,
    MapSection,
    // FinancingSection,
    SimilarPropertiesSection,
    Flickity,
    ContactModal,
    VisitModal,
    RentModal,
  },
  data() {
    return {
      flickityOptions: {
        prevNextButtons: true,
        arrowShape: {
          x0: 10,
          x1: 45,
          y1: 45,
          x2: 50,
          y2: 45,
          x3: 15,
        },
        pageDots: true,
        initialIndex: 0,
      },
      property: {
        photos: [],
        type: undefined,
        district: undefined,
        city: undefined,
        title: undefined,
        description: undefined,
        area: undefined,
        typology: undefined,
        wcs: undefined,
        garageSpaces: undefined,
        // elevators: undefined,
        value: undefined,
        ownerName: undefined,
        energeticClass: undefined,
        floor: undefined,
        characteristics: [],
        coordinates: {
          latitude: undefined,
          longitude: undefined,
        },
      },
      contactModalOpen: false,
      contactFields: {
        subject: undefined,
        description: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      },
      visitFields: {
        clientType: undefined,
        agency: undefined,
        amiLicense: undefined,
        date: undefined,
        hour: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      },
      rentFields: {
        clientType: undefined,
        agency: undefined,
        amiLicense: undefined,
        name: undefined,
        email: undefined,
        countryCode: undefined,
        phone: undefined,
      },
      visitModalOpen: false,
      rentModalOpen: false,
      similarProperties: [],
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
  async created() {
    /*
    this.location = cloneDeep(
      merge(
        this.savedLocation,
        removeNull(
          await this.$store.dispatch('getLocation', this.$route.params.id),
        ),
      ),
    );
    */
    if (!this.$route.params.id) {
      // TODO - error page
    }

    this.property = await this.$store.dispatch(
      'getProperty',
      this.$route.params.id,
    );

    this.similarProperties = (
      await this.$store.dispatch('getSimilarProperties', this.$route.params.id)
    ).properties;
  },
  methods: {
    closeContactModal() {
      this.contactModalOpen = false;
    },
    handleContactInput({ field, value }) {
      this.contactFields[field] = value;
    },
    async handleContactSubmition() {
      try {
        await this.$store.dispatch('sendPropertyContactEmail', {
          id: this.$route.params.id,
          fields: this.contactFields,
        });

        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.messageSuccess'),
          type: 'success',
        });

        this.closeContactModal();
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    closeVisitModal() {
      this.visitModalOpen = false;
    },
    handleVisitInput({ field, value }) {
      this.visitFields[field] = value;
    },
    async handleVisitSubmition() {
      try {
        await this.$store.dispatch('sendPropertyVisitEmail', {
          id: this.$route.params.id,
          fields: this.visitFields,
        });

        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.messageSuccess'),
          type: 'success',
        });

        this.closeVisitModal();
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
    closeRentModal() {
      this.rentModalOpen = false;
    },
    handleRentInput({ field, value }) {
      this.rentFields[field] = value;
    },
    async handleRentSubmition() {
      try {
        await this.$store.dispatch('sendPropertyRentEmail', {
          id: this.$route.params.id,
          fields: this.rentFields,
        });

        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.messageSuccess'),
          type: 'success',
        });

        this.closeRentModal();
      } catch (e) {
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.error'),
          type: 'error',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.photos-section {
  .carousel-photo {
    height: 720px;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  ::v-deep .flickity-page-dots {
    bottom: 25px;
  }

  ::v-deep .dot {
    background-color: $tertiary;
    border-radius: 4px;
    width: 32px;
    height: 4px;
    margin: 0 4px;
  }

  ::v-deep .flickity-button {
    background: unset;
    width: 110px;
    height: 110px;
    border-radius: unset;
    color: $tertiary;
  }
}

.gradient-section {
  background: linear-gradient(
    180deg,
    rgba($primary, 0.3) 0%,
    rgba($primary, 0) 100%
  );
}

.description-section {
  margin-bottom: 74px;
}

.characteristics-section {
  margin-bottom: 74px;
}

.map-section {
  margin-bottom: 110px;
}

.similar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  margin-bottom: 192px;

  .title {
    color: $grey;
    font-size: 40px;
    line-height: 72px;
    text-transform: uppercase;
  }

  .properties {
    display: flex;
    justify-content: center;
    gap: 72px;
  }

  .properties-mobile {
    width: 100%;
  }
}

@media only screen and (max-width: 970px) {
  .photos-section {
    .carousel-photo {
      height: 520px;
    }
  }

  .similar-section {
    gap: 0;
    margin-bottom: 120px;
  }
}

@media only screen and (max-width: 770px) {
  .photos-section {
    .carousel-photo {
      height: 300px;
    }

    ::v-deep .flickity-page-dots {
      bottom: 12px;
    }

    ::v-deep .dot {
      width: 16px;
      height: 2px;
    }

    ::v-deep .flickity-button {
      width: 0;
      height: 0;
    }
  }

  .description-section {
    margin-bottom: 56px;
  }

  .characteristics-section {
    margin-bottom: 56px;
  }

  .map-section {
    margin-bottom: 90px;
  }

  .similar-section {
    .title {
      font-size: 24px;
      line-height: 44px;
    }
  }
}
</style>
