<template>
  <div class="similar-section">
    <span class="title margin-container">
      {{ $t('sections.property-detail.similar') }}
    </span>
    <div
      class="properties margin-container"
      v-if="getBreakpoints.lgAndUp"
    >
      <PropertyCard
        v-for="(prop, index) in similarProperties"
        :key="`similar-property-${index}`"
        :property="prop"
        @click="
          $router.push({
            name: 'property-detail',
            params: {
              id: property._id,
            },
          })
        "
      />
    </div>
    <flickity
      class="properties-mobile"
      ref="similarProperties"
      :options="similarPropertiesflickityOptions"
      v-else
    >
      <PropertyCard
        v-for="(prop, index) in similarProperties"
        :key="`similar-property-mobile-${index}`"
        :property="prop"
        class="mb-8 mt-6"
        :class="
          index !== 0 &&
            index + 1 !== 3 &&
            (getBreakpoints.mdAndDown ? 'mx-7' : 'mx-10')
        "
        @click="
          $router.push({
            name: 'property-detail',
            params: {
              id: property._id,
            },
          })
        "
      />
    </flickity>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { PropertyCard } from 'ff-components-lib';
import Flickity from 'vue-flickity';

export default {
  name: 'SimilarPropertiesSection',
  components: {
    Flickity,
    PropertyCard,
  },
  props: {
    similarProperties: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      similarPropertiesflickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        initialIndex: 1,
      },
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints']),
  },
};
</script>
<style lang="scss" scoped>
.similar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;

  .title {
    color: $grey;
    font-size: 40px;
    line-height: 72px;
    text-transform: uppercase;
  }

  .properties {
    display: flex;
    justify-content: center;
    gap: 72px;
  }

  .properties-mobile {
    width: 100%;
  }
}

@media only screen and (max-width: 970px) {
  .similar-section {
    gap: 0;
  }
}

@media only screen and (max-width: 770px) {
  .similar-section {
    .title {
      font-size: 24px;
      line-height: 44px;
    }
  }
}
</style>
