var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GradientModal',{attrs:{"open":_vm.modalOpen,"button":_vm.$t('sections.property-detail.contactModal.button'),"button-disabled":!_vm.validFields},on:{"buttonClick":_vm.handleSubmit,"close":_vm.handleClose}},[_c('template',{slot:"default"},[_c('span',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.$t('sections.property-detail.contactModal.title'))+" ")]),_c('VerticalTitleInput',{staticClass:"inputs",attrs:{"title":_vm.$t('sections.property-detail.contactModal.inputs.subject.value'),"placeholder":_vm.$t('sections.property-detail.contactModal.inputs.subject.placeholder'),"value":_vm.inputs.subject,"valid":_vm.validSubject,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('subject', e); }}}),_c('VerticalTitleInput',{staticClass:"inputs",attrs:{"type":"textarea","title":_vm.$t('sections.property-detail.contactModal.inputs.description.value'),"placeholder":_vm.$t('sections.property-detail.contactModal.inputs.description.placeholder'),"value":_vm.inputs.description,"valid":_vm.validDescription,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('description', e); }}}),_c('VerticalTitleInput',{staticClass:"inputs",attrs:{"title":_vm.$t('sections.property-detail.contactModal.inputs.name.value'),"placeholder":_vm.$t('sections.property-detail.contactModal.inputs.name.placeholder'),"value":_vm.inputs.name,"valid":_vm.validName,"error-label":_vm.$t('components.input.missingField')},on:{"input":function (e) { return _vm.handleInput('name', e); }}}),_c('div',{staticClass:"row inputs m-0"},[_c('div',{staticClass:"col-sm-6 col-12 pl-0 pr-3 mb-sm-0 mb-6"},[_c('VerticalTitleInput',{attrs:{"title":_vm.$t(
              'sections.property-detail.contactModal.inputs.email.value'
            ),"placeholder":_vm.$t(
              'sections.property-detail.contactModal.inputs.email.placeholder'
            ),"value":_vm.inputs.email,"valid":this.validEmail,"error-label":_vm.$t('components.input.invalidEmail')},on:{"input":function (e) { return _vm.handleInput('email', e); }}})],1),_c('div',{staticClass:"col-sm-6 col-12 pl-3 pr-0 mb-sm-0 mb-6"},[_c('PhoneInput',{attrs:{"name":"phone-number","title":_vm.$t(
              'sections.property-detail.contactModal.inputs.phone.value'
            ),"placeholder":_vm.$t(
              'sections.property-detail.contactModal.inputs.phone.placeholder'
            ),"code-placeholder":_vm.$t(
              'sections.property-detail.contactModal.inputs.countryCode.placeholder'
            ),"valid":this.validPhone,"valid-code":this.validPhoneCountryCode,"error-label":_vm.$t('components.input.invalidPhone'),"value":_vm.inputs.phone,"code-value":_vm.inputs.countryCode,"items":_vm.$t('arrays.phoneCountryCodes')},on:{"input":function (e) { return _vm.handleInput('phone', e); },"codeInput":function (e) { return _vm.handleInput('countryCode', e); }}})],1)]),_c('BaseCheckbox',{staticClass:"inputs",attrs:{"label":_vm.$t('sections.property-detail.contactModal.consent'),"value":_vm.consent},on:{"input":function (e) { return _vm.consent = e; }}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }