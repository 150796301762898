var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('template',{slot:"body"},[(_vm.property.photos && _vm.property.photos.length > 0)?_c('flickity',{ref:"flickity",staticClass:"photos-section",attrs:{"options":_vm.flickityOptions}},_vm._l((_vm.property.photos),function(photo,index){return _c('div',{key:("property-image-" + index),staticClass:"carousel-photo"},[_c('img',{attrs:{"src":("https://storage.googleapis.com/bk-firstfloor-properties/" + (_vm.property._id) + "/photos/" + photo)}})])}),0):_c('div',{staticClass:"photos-section"},[_c('img',{staticClass:"carousel-photo",attrs:{"src":require("@/assets/imgs/property_placeholder.jpg")}})]),_c('div',{staticClass:"gradient-section"},[_c('DescriptionSection',{attrs:{"location":((_vm.property.district) + ", " + (_vm.property.city)),"title":_vm.property.title,"characteristics":{
          energeticClass: _vm.property.energeticClass,
          typology: _vm.property.typology,
          floor: _vm.property.floor,
          area: _vm.property.area,
          beds: _vm.property.typology ? Number(_vm.property.typology.slice(1)) : '',
          bathrooms: _vm.property.wcs,
          garageSpots: _vm.property.garageSpaces,
          // elevators: property.elevators,
        },"is-rent":_vm.property.listingType === 'RENT',"price":_vm.property.value,"description":_vm.property.description,"owner":{
          name: _vm.property.ownerName,
        }},on:{"contact":function($event){_vm.contactModalOpen = true},"visit":function($event){_vm.visitModalOpen = true},"proposal":function($event){_vm.rentModalOpen = true}}}),(_vm.property.characteristics && _vm.property.characteristics.length > 0)?_c('CharacteristicsSection',{attrs:{"characteristics":_vm.property.characteristics}}):_vm._e()],1),(
        _vm.property.coordinates &&
          _vm.property.coordinates.latitude != null &&
          _vm.property.coordinates.longitude != null
      )?_c('MapSection',{attrs:{"coordinates":[
        _vm.property.coordinates.latitude,
        _vm.property.coordinates.longitude ]}}):_vm._e(),(_vm.similarProperties && _vm.similarProperties.length > 0)?_c('SimilarPropertiesSection',{attrs:{"similar-properties":_vm.similarProperties}}):_vm._e(),_c('ContactModal',{attrs:{"modal-open":_vm.contactModalOpen},on:{"input":_vm.handleContactInput,"submit":_vm.handleContactSubmition,"close":_vm.closeContactModal}}),_c('VisitModal',{attrs:{"modal-open":_vm.visitModalOpen},on:{"input":_vm.handleVisitInput,"submit":_vm.handleVisitSubmition,"close":_vm.closeVisitModal}}),_c('RentModal',{attrs:{"modal-open":_vm.rentModalOpen},on:{"input":_vm.handleRentInput,"submit":_vm.handleRentSubmition,"close":_vm.closeRentModal}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }